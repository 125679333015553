import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Navbartop() {
  return (
    <>

<Navbar collapseOnSelect expand="lg" className="bg-primary fixed-top">
      <Container>
        <Navbar.Brand className='text-white text-lg' href="/">DigIndia</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            
          <Nav.Link className='text-white text-lg-start font-weight-bold'href="Header">Header</Nav.Link>
            <Nav.Link className='text-white text-lg-start'href="/">Sale Offer</Nav.Link>
            <Nav.Link className='text-white text-lg-start'href="About">About</Nav.Link>
           
           {/* <Nav.Link className='text-white text-lg-start'href="#features">Scanner</Nav.Link>
            <Nav.Link className='text-white text-lg-start'href="#features">Mobile</Nav.Link>
            <Nav.Link className='text-white text-lg-start'href="#features">Laptop Spare</Nav.Link>
            <Nav.Link className='text-white text-lg-start'href="#features">Desktop Spare</Nav.Link>
            <Nav.Link className='text-white text-lg-start'href="#features">Printer Spare</Nav.Link> */}
            <NavDropdown  title="" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <Nav.Link href="#deets">Best Price</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
              Smart Choice
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        
      </Container>
    </Navbar>
    </>
  );
}



  export default Navbartop;