import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "./assets/Images/Banner1.jpg";
import Image1 from "./assets/Images/ser.jpg";
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import  img1 from "./assets/Images/clock.jpg"
const Home = () => {
  return (
    <Container className="m-10 py-5">
      <Row>
        <Col>
        <img   src={Image} className="img-fluid m-10 align-center py-3 m-l" />
        </Col>
        
      
      </Row>
      <Row>
        <Col sm={6}> <img  src={Image1} className="img-fluid m-10 py-3" /></Col>
      
        <Col>
        <p className="m-3">A paragraph is a group of sentences that support a single main idea. Paragraphs are a key tool for organizing writing and helping readers understand the structure of a piece. Here are some characteristics of a well-written A paragraph is a group of sentences that support a single main idea. Paragraphs are a key tool for organizing writing and helping readers understand the structure of a piece. Here are some characteristics of a well-writtenA paragraph is a group of sentences that support a single main idea. Paragraphs are a key tool for organizing writing and helping readers understand the structure of a piece. Here are some characteristics of a well-writtenA paragraph is a group of sentences that support a single main idea. Paragraphs are a key tool for organizing writing and helping readers understand the structure of a piece. Here are some characteristics of a well-written</p>
        
        </Col>



      </Row>

      <Row className="m-3 py-1">
      <Card style={{ width: '18rem', margin:10 }}>
    <Card.Img style={{width: 250, height: 200, alignItems:'center'}} variant="top" src={img1} />
      <Card.Body>
        <Card.Title className='text-center'>Card Title</Card.Title>
        <Card.Text className='text-center'>
          Some quick example text to build on the card title and make up th
        </Card.Text> 
        <Button style={{width:100, height:40}}href="/Mobileimg3" variant="primary">500</Button>
        <Button className='m-2 py-2 text-center w-25'href="/Mobileimg3" variant="primary">Buy</Button>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem', margin:10 }}>
    <Card.Img style={{width: 250, height: 200, alignItems:'center'}} variant="top" src={img1} />
      <Card.Body>
        <Card.Title className='text-center'>Card Title</Card.Title>
        <Card.Text className='text-center'>
          Some quick example text to build on the card title and make up th
        </Card.Text> 
        <Button style={{width:100, height:40}}href="/Mobileimg3" variant="primary">500</Button>
        <Button className='m-2 py-2 text-center w-25'href="/Mobileimg3" variant="primary">Buy</Button>
      </Card.Body>
    </Card>
    

    <Card style={{ width: '18rem', margin:10 }}>
    <Card.Img style={{width: 250, height: 200, alignItems:'center'}} variant="top" src={img1} />
      <Card.Body>
        <Card.Title className='text-center'>Card Title</Card.Title>
        <Card.Text className='text-center'>
          Some quick example text to build on the card title and make up th
        </Card.Text> 
        <Button style={{width:100, height:40}}href="/Mobileimg3" variant="primary">500</Button>
        <Button className='m-2 py-2 text-center w-25'href="/Mobileimg3" variant="primary">Buy</Button>
      </Card.Body>
    </Card>
    

    <Card style={{ width: '18rem', margin:10 }}>
    <Card.Img style={{width: 250, height: 200, alignItems:'center'}} variant="top" src={img1} />
      <Card.Body>
        <Card.Title className='text-center'>Card Title</Card.Title>
        <Card.Text className='text-center'>
          Some quick example text to build on the card title and make up th
        </Card.Text> 
        <Button style={{width:100, height:40}}href="/Mobileimg3" variant="primary">500</Button>
        <Button className='m-2 py-2 text-center w-25'href="/Mobileimg3" variant="primary">Buy</Button>
      </Card.Body>
    </Card>
    









      </Row>
    </Container>

    
  );
};

export default Home;
