import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Navbartop from "./Navbartop";

import Watch from "./Watch";
import Header from "./Header";
import Contact from "./Contact";



const App = () => {
  return (
    <div className="App">

      <Navbartop />
      
      <Routes>
        <Route path="/" element={<Home />}>
          Home
        </Route>
        <Route path="/About" element={<About />}>
          About
        </Route>

    

        <Route path="/Watch" element={<Watch />}>
          Watch
        </Route>

      
        
        <Route path="/Header" element={<Header />}>
        Header
        </Route>
        <Route path="/Contact" element={<Contact />}>
        Header
        </Route>

      </Routes>
    </div>
  );
};

export default App; 
